<template>
  <div class="mo-reserve-wrap">
    <div class="mo-reserve-wrap--title">참여완료</div>
    <div class="mo-reserve-wrap--container">
      <div class="mo-reserve-wrap--done-chk">방문 예약이 완료 되었습니다.</div>
      <div class="mo-reserve-wrap--print">
        <div v-for="(item, index) in PRINT_LIST" :key="index"  class="mo-reserve-wrap--print-list" >
          <strong class="mo-reserve-wrap--print-list-title">{{ item.LIST_TITLE }}</strong>
          <span class="mo-reserve-wrap--print-list-txt">{{ item.LIST_TEXT }}</span>
        </div>
      </div>
      <div class="mo-reserve-wrap--print-subtxt">예약을 변경하시려면 <u>변경</u> 버튼을 선택해 주세요.</div>
    </div>
    <div class="mo-reserve-wrap--btn">
      <button type="button" class="btn-primary">변경</button>
      <button type="button">확인</button>
    </div>

  </div>
  </template>

  <script>
    export default {
      name: 'CAP0610',
      components: {},
      props: {},
      data() {
        return {
          PRINT_LIST: [
            {
              LIST_TITLE: '내용',
              LIST_TEXT: '더샵 거창포르시열 2차 방문예약',
            },
            {
              LIST_TITLE: '기간',
              LIST_TEXT: '2022. 08.05(금) 08:00 ~ 2022. 08.10(수) 23:00   ',
            },
            {
              LIST_TITLE: '예약',
              LIST_TEXT: '2022. 08.05(금) 10:30',
            },
          ]

        }
      },
      mounted() {},
      computed: {},
      methods: {

      }
    }
  </script>

  <style>

  </style>